import React from 'react';
import css from './RichTextDisplay.module.css';

const RichTextDisplay = ({ content }) => {
  if (!content) {
    return null;
  }

  const formattedContent = content.replaceAll('＜', '<').replaceAll('＞', '>');
  return <div className={css.content} dangerouslySetInnerHTML={{ __html: formattedContent }} />;
};

export default RichTextDisplay;
